// create a component called Assistant
// it will display the introduction text

import styles from "./Assistant.module.css"

import { Stack } from "@fluentui/react"

export const Assistant = () => {
    return (
        <Stack className={styles.assistantContainer} tabIndex={0}>
            <Stack.Item grow>
                <div className={styles.assistantText}>
                    Hello, I'm the AMETEK AI Chatbot. I'm designed to help with product and service inquiries.
                                            To begin, simply type a question. For example, "Do you have information on baseline gas analyzers?" or, "Where can I buy a spectrometer?"
                                            The more details you provide, the better I can assist you. I'm a learning chatbot so my responses may be limited in their accuracy.
                </div>
            </Stack.Item>
        </Stack>
    )
}