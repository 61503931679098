import { useEffect, useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks"
import { FontIcon, Link, Stack, Text } from "@fluentui/react";

import styles from "./Answer.module.css";

import { AskResponse, Citation } from "../../api";
import { parseAnswer } from "./AnswerParser";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub'

interface Props {
    answer: AskResponse;
}

export const Answer = ({
    answer,
}: Props) => {
    const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);

    const createUrl = (citation: Citation, index: number) => {
        
        let citationFilename = "";
        parseInt(citation.chunk_id ?? '') + index
        citationFilename = `${citation.filepath}`

        return citationFilename;
    }

    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <Stack.Item grow>
                    <ReactMarkdown
                        linkTarget="_blank"
                        remarkPlugins={[remarkGfm, supersub]}
                        children={parsedAnswer.markdownFormatText}
                        className={styles.answerText}
                    />
                </Stack.Item>

                    <div className={styles.answerText} style={{ marginTop: 8, display: "flex", flexFlow: "wrap column", gap: "4px" }}>
                        {parsedAnswer.citations.map((citation, idx) => {
                            const index = ++idx;
                            return (
                                <span 
                                    title={createUrl(citation, index)} 
                                    tabIndex={0} 
                                    role="link" 
                                    key={idx} 
                                >
                                   
                                    <div className={styles.citation}>{index}</div>
                                     <Link href={createUrl(citation, index)} target="_blank">{createUrl(citation, index)}</Link>
                                </span>);
                        })}
                       <br/>

                       <span className={styles.answerDisclaimer}>AI-generated content may be incorrect</span>
                    </div>
            </Stack>
        </>
    );
};
