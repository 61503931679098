import { AskResponse, Citation } from "../../api";
import { cloneDeep } from "lodash-es";


type ParsedAnswer = {
    citations: Citation[];
    otherCitations: Citation[];
    markdownFormatText: string;
};

export function parseAnswer(answer: AskResponse): ParsedAnswer {

    let answerText = answer.answer;
    const citationLinks = answerText.match(/\[(doc\d\d?\d?)]/g);
    const lengthDocN = "[doc".length;

    let filteredCitations = [] as Citation[];
    
    let citationReindex = 0;
    
    citationLinks?.forEach(link => {
        // Replacing the links/citations with number
        let citationIndex = link.slice(lengthDocN, link.length - 1);
        let citation = cloneDeep(answer.citations[Number(citationIndex) - 1]) as Citation;
        if (!filteredCitations.find((c) => c.id === citationIndex)) {
          answerText = answerText.replaceAll(link, ` ^${++citationReindex}^ `);
          citation.id = citationIndex; // original doc index to de-dupe
          citation.reindex_id = citationReindex.toString(); // reindex from 1 for display
          filteredCitations.push(citation);
        }
    })
    

    // contract all other citations that are not part of citationLinks
    let otherCitations = [] as Citation[];

    answer.citations.forEach((citation, index) => {
        if (!filteredCitations.find((c) => c.id === citation.id)) {
            citation.id = (index + 1).toString(); // original doc index to de-dupe
            citation.reindex_id = citation.id; // reindex from 1 for display
            otherCitations.push(citation);
        }
    });

    return {
        citations: filteredCitations,
        otherCitations: otherCitations,
        markdownFormatText: answerText
    };
}
