import { useRef, useState, useEffect, useContext, useLayoutEffect } from "react";
import { CommandBarButton, IconButton, Dialog, DialogType, Stack, Persona, PersonaInitialsColor, PersonaSize, Link } from "@fluentui/react";
import { SquareRegular, ErrorCircleRegular } from "@fluentui/react-icons";

import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import uuid from 'react-uuid';
import { isEmpty } from "lodash-es";

import styles from "./Chat.module.css";
import Logo from "../../assets/ametek-logo.png";

import {
    ChatMessage,
    ConversationRequest,
    conversationApi,
    Citation,
    ToolMessageContent,
    ChatResponse,
    Conversation,
    ErrorMessage
} from "../../api";

import { Answer } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { AppStateContext } from "../../state/AppProvider";
import { useBoolean } from "@fluentui/react-hooks";
import { AnswerIcon } from "../../components/AnswerIcon/AnswerIcon";
import { Assistant } from "../../components/Assistant/Assistant";

import Joyride, { CallBackProps, STATUS,  Step } from "react-joyride";
import { useSetState } from "react-use";

const enum messageStatus {
    NotRunning = "Not Running",
    Processing = "Processing",
    Done = "Done"
}

interface State {
  run: boolean;
  steps: Step[];
}

const Chat = () => {
    const appStateContext = useContext(AppStateContext)
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
    const [isCitationPanelOpen, setIsCitationPanelOpen] = useState<boolean>(false);
    const abortFuncs = useRef([] as AbortController[]);
    const [messages, setMessages] = useState<ChatMessage[]>([])
    const [processMessages, setProcessMessages] = useState<messageStatus>(messageStatus.NotRunning);
    const [clearingChat] = useState<boolean>(false);
    const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true);
    const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>()

    const [isJoyRideSaved, setJoyRideSaved] = useState<boolean>(localStorage.getItem('isJoyRideSaved') === 'true');
    useEffect(() => {
        localStorage.setItem('isJoyRideSaved', String(isJoyRideSaved));
    }, [isJoyRideSaved]);

    const [{ run, steps }, setState] = useSetState<State>({
        run: true,
        steps: [
            {
                target: "#broomId",
                content: "Want to start over? Click here to erase the conversation.",
                disableBeacon: true,
            },
        ],
    });

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status, type } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setState({ run: false });
            if (!isJoyRideSaved){
                setJoyRideSaved(true);
            }
        }
        
        console.groupCollapsed(type);
        console.log(data);
        console.groupEnd();
    }

    const errorDialogContentProps = {
        type: DialogType.close,
        title: errorMsg?.title,
        closeButtonAriaLabel: 'Close',
        subText: errorMsg?.subtitle,
    };

    const modalProps = {
        titleAriaId: 'labelId',
        subtitleAriaId: 'subTextId',
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
    }

    const [ASSISTANT, TOOL, ERROR] = ["assistant", "tool", "error"]

    const handleErrorDialogClose = () => {
        toggleErrorDialog()
        setTimeout(() => {
            setErrorMsg(null)
        }, 500);
    }

    let assistantMessage = {} as ChatMessage
    let toolMessage = {} as ChatMessage
    let assistantContent = ""

    const processResultMessage = (resultMessage: ChatMessage, userMessage: ChatMessage, conversationId?: string) => {
        if (resultMessage.role === ASSISTANT) {
            assistantContent += resultMessage.content
            assistantMessage = resultMessage
            assistantMessage.content = assistantContent
        }

        if (resultMessage.role === TOOL) toolMessage = resultMessage

        if (!conversationId) {
            isEmpty(toolMessage) ?
                setMessages([...messages, userMessage, assistantMessage]) :
                setMessages([...messages, userMessage, toolMessage, assistantMessage]);
        } else {
            isEmpty(toolMessage) ?
                setMessages([...messages, assistantMessage]) :
                setMessages([...messages, toolMessage, assistantMessage]);
        }
    }

    const makeApiRequestAsync = async (question: string, conversationId?: string) => {
        setIsLoading(true);
        setShowLoadingMessage(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        const userMessage: ChatMessage = {
            id: uuid(),
            role: "user",
            content: question,
            date: new Date().toISOString(),
        };


        let conversation: Conversation | null | undefined;
        if(!conversationId){
            conversation = {
                id: conversationId ?? uuid(),
                title: question,
                messages: [userMessage],
                date: new Date().toISOString(),
            }
        }else{
            conversation = appStateContext?.state?.currentChat
            if(!conversation){
                console.error("Conversation not found.");
                setIsLoading(false);
                setShowLoadingMessage(false);
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                return;
            }else{
                conversation.messages.push(userMessage);
            }
        }

        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
        setMessages(conversation.messages)
        
        const request: ConversationRequest = {
            messages: [...conversation.messages.filter((answer) => answer.role !== ERROR)]
        };

        let result = {} as ChatResponse;
        try {
            const response = await conversationApi(request, abortController.signal);
            if (response?.body) {
                const reader = response.body.getReader();
                let runningText = "";

                while (true) {
                    setProcessMessages(messageStatus.Processing)
                    const {done, value} = await reader.read();
                    if (done) break;

                    var text = new TextDecoder("utf-8").decode(value);
                    
                    // during streaming some error might be returned
                    if (text.includes('exceptionMessage')) {
                        console.log(`Text at error throw: ${text}`);
                        throw new Error(text);
                    }

                    const objects = text.split("\n");
                    objects.forEach((obj) => {
                        try {
                            runningText += obj;
                            result = JSON.parse(runningText);
                            result.choices[0].messages.forEach((obj) => {
                                obj.id = uuid();
                                obj.date = new Date().toISOString();
                            })
                            setShowLoadingMessage(false);
                            result.choices[0].messages.forEach((resultObj) => {
                                processResultMessage(resultObj, userMessage, conversationId);
                            })
                            runningText = "";
                        }
                        catch { }
                    });
                }
                
                // https://github.com/microsoft/sample-app-aoai-chatGPT/issues/366
                //conversation.messages.push(toolMessage, assistantMessage)
                isEmpty(toolMessage) ?   conversation.messages.push(assistantMessage) : conversation.messages.push(toolMessage, assistantMessage) 

                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                
                // https://github.com/microsoft/sample-app-aoai-chatGPT/issues/366
                isEmpty(toolMessage) ?  setMessages([...messages, assistantMessage]) :  setMessages([...messages, toolMessage, assistantMessage]); 
                //setMessages([...messages, toolMessage, assistantMessage]);
            }
            
        } catch ( e: any | string)  {
            if (!abortController.signal.aborted) {
                
                // disable e displayed in the chat in production
                console.error(e);

                // check if e as string contains content_filter_results
                if (e.message.includes("content_filter")) {
                    let contentFilterMessage: ChatMessage = {
                        id: uuid(),
                        role: ASSISTANT,
                        content: "Sorry, I can't help with that question.",
                        date: new Date().toISOString()
                    }
                    conversation.messages.push(contentFilterMessage);
                    appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                    setMessages([...messages, contentFilterMessage])
                }
                else{
                    let errorMessage = "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
                    if (result.error?.message) {
                        errorMessage = result.error.message;
                    }
                    else if (typeof result.error === "string") {
                        errorMessage = result.error;
                    }
                    let errorChatMsg: ChatMessage = {
                        id: uuid(),
                        role: ERROR,
                        content: errorMessage,
                        date: new Date().toISOString()
                    }
                    conversation.messages.push(errorChatMsg);
                    appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                    setMessages([...messages, errorChatMsg]);
                }
            } else {
                setMessages([...messages, userMessage])
            }

        } finally {
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            setProcessMessages(messageStatus.Done)
        }

        return abortController.abort();
    };

    const newChat = () => {
        setProcessMessages(messageStatus.Processing)
        setMessages([])
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: null });
        setProcessMessages(messageStatus.Done)
    };

    const stopGenerating = () => {
        abortFuncs.current.forEach(a => a.abort());
        setShowLoadingMessage(false);
        setIsLoading(false);
    }

    useEffect(() => {
        if (appStateContext?.state.currentChat) {
            setMessages(appStateContext.state.currentChat.messages)
        }else{
            setMessages([])
        }
    }, [appStateContext?.state.currentChat]);
    
    useLayoutEffect(() => {
        if (appStateContext && appStateContext.state.currentChat && processMessages === messageStatus.Done) {
                setMessages(appStateContext.state.currentChat.messages)
            setProcessMessages(messageStatus.NotRunning)
        }
    }, [processMessages]);

 
    useLayoutEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" })
    }, [showLoadingMessage, processMessages]);

    const parseCitationFromMessage = (message: ChatMessage) => {
        if (message?.role && message?.role === "tool") {
            try {
                const toolMessage = JSON.parse(message.content) as ToolMessageContent;
                return toolMessage.citations;
            }
            catch {
                return [];
            }
        }
        return [];
    }

    const disabledButton = () => {
        return isLoading || (messages && messages.length === 0) || clearingChat
    }

    return (
    <div className={styles.container} role="main" id="chatContainer">
            <Stack horizontal className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!messages || messages.length < 1 ? (
                        <div className={styles.chatMessageStream} style={{ marginBottom: "0px"}}  role="intro">
                            <div className={styles.chatMessageGpt}>
                                <Assistant/>
                                <div className={styles.answerLogo}><AnswerIcon /></div>
                            </div>
                            <div  className={styles.chatMessageUser}>
                                <div className={styles.chatMessageUserMessage}>
                                    <Link onClick={() =>makeApiRequestAsync(`Tell me more about AMETEK's businesses.`, appStateContext?.state.currentChat?.id)}>Tell me more about AMETEK's businesses.</Link>
                               </div>
                               <Persona initialsColor={PersonaInitialsColor.rust} size={PersonaSize.size24} hidePersonaDetails />
                            </div>
                        </div>

                    ) : (
                        <div className={styles.chatMessageStream} style={{ marginBottom: isLoading ? "40px" : "0px"}} role="log">
                            {messages.map((answer, index) => (
                                <>
                                    {answer.role === "user" ? (
                                        <div className={styles.chatMessageUser} tabIndex={0}>
                                            <div className={styles.chatMessageUserMessage}>{answer.content}</div>
                                            <Persona initialsColor={PersonaInitialsColor.rust} size={PersonaSize.size24} hidePersonaDetails />
                                        </div>
                                    ) : answer.role === "assistant" ? (
                                         <div className={styles.chatMessageGpt}>
                                                <Answer
                                                    answer={{
                                                        answer: answer.content,
                                                        citations: parseCitationFromMessage(messages[index - 1]),
                                                    }}
                                                />
                                                <div className={styles.answerLogo}><AnswerIcon /></div>
                                            </div>
                                    ) : answer.role === ERROR ? (
                                                <div className={styles.chatMessageError}>
                                                <Stack horizontal className={styles.chatMessageErrorContent}>
                                                    <ErrorCircleRegular className={styles.errorIcon} style={{color: "rgba(182, 52, 67, 1)"}} />
                                                    <span>Error</span>
                                                </Stack>
                                                <span className={styles.chatMessageErrorContent}>{answer.content}</span>
                                                </div>
                                       ) : null}
                                </>
                            ))}
                            {showLoadingMessage && (
                                <>
                                    <div className={styles.chatMessageGpt}>
                                        <Answer
                                            answer={{
                                                answer: "Generating answer...",
                                                citations: []
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}

                    <Stack horizontal className={styles.chatInput}>
                        {isLoading && (
                            <Stack 
                                horizontal
                                className={styles.stopGeneratingContainer}
                                role="button"
                                aria-label="Stop generating"
                                tabIndex={0}
                                onClick={stopGenerating}
                                onKeyDown={e => e.key === "Enter" || e.key === " " ? stopGenerating() : null}
                                >
                                    <SquareRegular className={styles.stopGeneratingIcon} aria-hidden="true"/>
                                    <span className={styles.stopGeneratingText} aria-hidden="true">Stop generating</span>
                            </Stack>
                        )}
                      


                        {(!disabledButton() && !isJoyRideSaved) && 
                           <Joyride
                                debug={true}
                                callback={handleJoyrideCallback}
                                run={run}
                                steps={steps}
                                styles={{
                                        options: {
                                            zIndex: 10000,
                                        },
                                        }}/>
                        }

                        <Stack>
                            <CommandBarButton
                                id="broomId"
                                role="button"
                                styles={{ 
                                    icon: { 
                                        color: '#040404',
                                    },
                                    root: {
                                        color: '#040404',
                                        background: disabledButton() ? "#BDBDBD" : "#fafafa",
                                        cursor: disabledButton() ? "" : "pointer"
                                    },
                                }}
                                className={styles.clearChatBroom}
                                iconProps={{ iconName: 'Broom' }}
                                onClick={newChat}
                                disabled={disabledButton()}
                                aria-label="clear chat button"
                                title="Want to start over? Click here to erase the conversation."
                            />
                            <Dialog
                                hidden={hideErrorDialog}
                                onDismiss={handleErrorDialogClose}
                                dialogContentProps={errorDialogContentProps}
                                modalProps={modalProps}
                            >
                            </Dialog>
                        </Stack>
                        <QuestionInput
                            clearOnSend
                            placeholder="Type a new question..."
                            disabled={isLoading}
                            onSend={(question, id) => {
                                makeApiRequestAsync(question, id)
                            }}
                            conversationId={appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined}
                        />
                    </Stack>
                </div>
            </Stack>
        </div>
    );
};

export default Chat;
