import { Outlet } from "react-router-dom";
import styles from "./Layout.module.css";
import { Stack } from "@fluentui/react";


const Layout = () => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
                    <Stack horizontal tokens={{ childrenGap: 4 }}>
                    </Stack>
                </Stack>
            </header>
            <Outlet />
        </div>
    );
};

export default Layout;
