import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import { initializeIcons, ThemeProvider, createTheme } from "@fluentui/react";

import "./index.css";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import { AppStateProvider } from "./state/AppProvider";

initializeIcons();

// Custom theme for Fluent UI components
const robotTheme = createTheme({
  fonts: {
    medium: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 'normal',
      fontSize: '14px',
    },
    large: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 'semi-bold',
      fontSize: '18px',
    },
    xLarge: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 'bold',
      fontSize: '22px',
    },
    // Add other customizations as needed
  },
  // Include other theme customizations as needed
});


export default function App() {
    return (
        <AppStateProvider>
          <ThemeProvider theme={robotTheme} applyTo="body">
            <HashRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Chat />} />
                        <Route path="*" element={<NoPage />} />
                    </Route>
                </Routes>
            </HashRouter>
        </ThemeProvider>
        </AppStateProvider>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
